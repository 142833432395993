import { useState, useCallback } from "react"
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Modal from "./Modal"
import useForm from "../hooks/useForm"
import FormFieldText from "./Form/FormFieldText"
import FormFieldPhone from "./Form/FormFieldPhone"
import { PAYMENT_TYPES, paymentTypes, deliveryTypeElectronicProductSlug, PRODUCT_ID_COURSE } from '../utils/enums'
import { storeOrder } from '../utils/api'
import { lastOrderSet } from '../features/lastOrder/lastOrderSlice'

const EducationModal = ({ course, isOpen, onClose }) => {
  console.log(course)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [step, setStep] = useState(1)
  const [selectedSlot, setSelectedSlot] = useState(null)

  const onOrderSuccess = (order) => {
    dispatch(lastOrderSet(order))

    navigate('/thank-you')
  }

  const {
    fields,
    setFields,
    invalidFields,
    validateFieldMemoized,
    setField,
    setFieldMemoized,
    setFieldMultipleMemoized,
    handleSubmit,
    isSubmitting,
  } = useForm({
    seats: 1,
    phoneCode: "+7",
    phoneNumber: "___ ___ __ __",
    paymentWireOrg: '',
    paymentWireInn: '',
    paymentWireKpp: '',
    paymentWireAcc: '',
    paymentWireCorrAcc: '',
    paymentWireBik: '',
  }, {
    seats: (value) => isNaN(value) || value < 1,
    surname: (value) => value?.length < 1,
    name: (value) => value?.length < 1,
    phoneNumber: (value) => value?.length < 1 || value.indexOf('_') != -1,
    email: (value) => value?.length < 1 || !value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i),
    // paymentWireOrg: (value) => value?.length < 1,
    // paymentWireInn: (value) => value?.length < 1,
    // paymentWireKpp: (value) => value?.length < 1,
    // paymentWireAcc: (value) => value?.length < 1,
    // paymentWireCorrAcc: (value) => value?.length < 1,
    // paymentWireBik: (value) => value?.length < 1,
  }, async (fields) => {
    const phone = (`${fields.phoneCode}${fields.phoneNumber}`).replaceAll(' ', '')
    const isWire = step == 5 // TODO is this enough?

    let comments = {
      type: 'course',
      course_id: course.id,
      slot_id: selectedSlot,
      course_name: course.name,
      seats: fields.seats,
    }

    const data = {
      surname: fields.surname,
      name: fields.name,
      phoneCode: fields.phoneCode,
      phoneNumber: fields.phoneNumber,
      email: fields.email,
      comments: JSON.stringify(comments),
      paymentType: isWire ? paymentTypes.find((elem) => elem.type === PAYMENT_TYPES.WIRE).slug : paymentTypes.find((elem) => elem.type === PAYMENT_TYPES.CARD).slug,
      deliveryType: deliveryTypeElectronicProductSlug,
      cartItems: [
        { product_id: PRODUCT_ID_COURSE, variation: { id: PRODUCT_ID_COURSE }, qty: fields.seats, is_on_sale: false, priceOverride: course.price * 100 },
      ],
      cartTotalSum: course.price * fields.seats,
      isForAnotherPerson: false,
    }

    if (isWire) {
      data.paymentWireAcc = fields.paymentWireAcc
      data.paymentWireBik = fields.paymentWireBik
      data.paymentWireCorrAcc = fields.paymentWireCorrAcc
      data.paymentWireInn = fields.paymentWireInn
      data.paymentWireKpp = fields.paymentWireKpp
      data.paymentWireOrg = fields.paymentWireOrg
    }

    console.log(data)
    
    const order = await storeOrder(data)

    if (!order?.id) {
      // alert('Произошла ошибка при оформлении заказа; пожалуйста, расскажите об этом нам по телефону или по электронной почте.')
      return
    }

    if (isWire) {
      onOrderSuccess(order)
      return
    }

    // eslint-disable-next-line
    const widget = new cp.CloudPayments()

    widget.charge(
      {
        publicId: import.meta.env.VITE_CLOUDPAYMENTS_PUBLIC_ID,
        // accountId: `${result.id}_${Date.now()}`,
        invoiceId: order.id,
        description: "Оплата заказа",
        amount: course.price * fields.seats,
        currency: "RUB",
        // email: fields.email,
        configuration: {
          paymentMethodsOrder: [
            cp.constant.ExternalPaymentMethods.SbpPay,
            cp.constant.ExternalPaymentMethods.MirPay,
            cp.constant.ExternalPaymentMethods.TinkoffPay,
          ]
        },
        data: {
          phone: phone,
          CloudPayments: {
            CustomerReceipt: {
              Items: [
                {
                  "label": course.name,
                  "price": course.price,
                  "quantity": fields.seats,
                  "amount": course.price * fields.seats,
                  // "vat": 0,
                  "method": 1,
                  "object": 1,
                }
              ],
              taxationSystem: 2, // система налогообложения; необязательный, если у вас одна система налогообложения
              // email: fields.email, // e-mail покупателя, если нужно отправить письмо с чеком
              phone: phone, // телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
              isBso: false, // чек является бланком строгой отчетности
              amounts: {
                electronic: course.price * fields.seats, // Сумма оплаты электронными деньгами
                advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
                credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после запятой)
                provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
              },
            },
          },
        },
      },
      function (options) {
        // success

        console.log('success')
        console.log(options)

        onOrderSuccess(order)
      },
      function (reason, options) {
        // fail

        console.log('fail')
        console.log(reason, options)
      },
      function (paymentResult, options) {
        // onComplete
        // Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
        // например вызов вашей аналитики Facebook Pixel
      }
    )
  })

  const handleClose = () => {
    setStep(1)
    onClose()
  }

  const availableSeats = course?.course_slots?.find(slot => slot.id === selectedSlot)
    ? course.course_slots.find(slot => slot.id === selectedSlot)?.max_students - course.course_slots.find(slot => slot.id === selectedSlot)?.students_count
    : 0
  
  const buttonDisabled = (
      (step == 1 && selectedSlot === null)
   || (step == 1 && availableSeats < 1)
   || (step == 2 && (isNaN(fields.seats) || fields.seats < 1 || fields.seats > availableSeats))
   || (step == 3 && (fields.surname?.length < 1 || fields.name?.length < 1 || fields.phoneNumber?.length < 1 || fields.phoneNumber?.indexOf('_') != -1 || fields.email?.length < 1 || !fields.email?.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_``{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)))
   || (step == 5 && (fields.paymentWireOrg?.length < 1 || fields.paymentWireInn?.length < 1 || fields.paymentWireKpp?.length < 1 || fields.paymentWireAcc?.length < 1 || fields.paymentWireCorrAcc?.length < 1 || fields.paymentWireBik?.length < 1))
  )

  const slots = (course?.course_slots && course.course_slots.length > 0) ? course.course_slots : []
  const slotsSorted = [...slots].sort((a, b) => new Date(a.start) - new Date(b.start))

  return (
    <Modal heading="Записаться на курс" isOpen={isOpen} onClose={handleClose}>
      {(!course?.course_slots || course?.course_slots?.length == 0) && <div>К сожалению, на данный момент нет свободных мест для записи на это мероприятие.</div>}
      <div className={`${(!course?.course_slots || course?.course_slots?.length == 0) ? 'display-none' : ''}`}>
        <div className={`education-modal-block ${step != 1 ? 'display-none' : ''}`}>
          <div>Выберите дату и время</div>
          <div className="education-modal-slots">
            {slotsSorted.map((slot, index) => (
              <div key={slot.id} className={`cart-form-pellet ${slot.id === selectedSlot ? 'active' : ''}`} onClick={() => setSelectedSlot(slot.id)}>
                <div>{new Date(slot.start).toLocaleString('ru-RU', { timeZone: 'UTC', day: 'numeric', month: 'long', year: 'numeric' })} {new Date(slot.start).toISOString().split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')}</div>
                <div>{(slot.max_students - slot.students_count > 0) ? `Осталось мест: ${slot.max_students - slot.students_count}` : `К сожалению, свободных мест не осталось`}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={`education-modal-block ${step != 2 ? 'display-none' : ''}`}>
          <div>Выберите количество мест (максимум {availableSeats})</div>
          {/* <FormFieldText
            value={fields.seats}
            isInvalid={false}
            placeholder={'Количество мест'}
            onChange={setFieldMemoized('seats')}
          /> */}
          <div className="catalog-product-option catalog-product-option__qty" style={{maxWidth: '150px'}}>
            <div onClick={() => fields.seats > 1 && setField('seats')(fields.seats - 1)}>-</div>
            <div>{fields.seats}</div>
            <div onClick={() => fields.seats < availableSeats && setField('seats')(fields.seats + 1)}>+</div>
          </div>
        </div>
        <div className={`education-modal-block ${step != 3 ? 'display-none' : ''}`}>
          <div>Введите ваши данные</div>
          <FormFieldText
            value={fields?.surname ?? ''}
            isInvalid={invalidFields.surname}
            placeholder={'Фамилия'}
            onChange={setFieldMemoized('surname')}
            validateField={validateFieldMemoized('surname')}
          />
          <FormFieldText
            value={fields?.name ?? ''}
            isInvalid={invalidFields.name}
            placeholder={'Имя'}
            onChange={setFieldMemoized('name')}
            validateField={validateFieldMemoized('name')}
          />
          <FormFieldPhone
            phoneCode={fields?.phoneCode ?? ''}
            phoneNumber={fields?.phoneNumber ?? ''}
            isInvalid={invalidFields.phone}
            setPhoneCode={useCallback((phoneCode, mask) => {
              setFieldMultiple({
                phoneCode: phoneCode,
                phoneNumber: mask,
              })
            }, [])}
            setPhoneNumber={setFieldMemoized('phoneNumber')}
            validateField={validateFieldMemoized('phone')}
          />
          <FormFieldText
            value={fields?.email ?? ''}
            isInvalid={invalidFields.email}
            placeholder={'Эл. почта'}
            onChange={setFieldMemoized('email')}
            validateField={validateFieldMemoized('email')}
          />
        </div>
        <div className={`education-modal-block ${step != 4 ? 'display-none' : ''}`}>
          <button className={`button-big-primary-wide ${(step == 4 && isSubmitting) ? 'disabled' : ''}`} onClick={handleSubmit}>{(step == 4 && isSubmitting) ? 'Оформляем' : 'Оплата банковской картой или СБП'}</button>
          <button className="button-big-primary-wide" onClick={() => !buttonDisabled && setStep(step + 1)}>Оплата как юридическое лицо</button>
        </div>
        <div className={`education-modal-block ${step != 5 ? 'display-none' : ''}`}>
          <FormFieldText
            value={fields?.paymentWireOrg ?? ''}
            onChange={setFieldMemoized('paymentWireOrg')}
            placeholder={'Наименование организации'}
            isInvalid={invalidFields.paymentWireOrg}
            validateField={validateFieldMemoized('paymentWireOrg')}
          />
          <FormFieldText
            value={fields?.paymentWireInn ?? ''}
            onChange={setFieldMemoized('paymentWireInn')}
            placeholder={'ИНН'}
            isInvalid={invalidFields.paymentWireInn}
            validateField={validateFieldMemoized('paymentWireInn')}
          />
          <FormFieldText
            value={fields?.paymentWireKpp ?? ''}
            onChange={setFieldMemoized('paymentWireKpp')}
            placeholder={'КПП'}
            isInvalid={invalidFields.paymentWireKpp}
            validateField={validateFieldMemoized('paymentWireKpp')}
          />
          <FormFieldText
            value={fields?.paymentWireAcc ?? ''}
            onChange={setFieldMemoized('paymentWireAcc')}
            placeholder={'Расчётный счёт'}
            isInvalid={invalidFields.paymentWireAcc}
            validateField={validateFieldMemoized('paymentWireAcc')}
          />
          <FormFieldText
            value={fields?.paymentWireCorrAcc ?? ''}
            onChange={setFieldMemoized('paymentWireCorrAcc')}
            placeholder={'Корр. счёт'}
            isInvalid={invalidFields.paymentWireCorrAcc}
            validateField={validateFieldMemoized('paymentWireCorrAcc')}
          />
          <FormFieldText
            value={fields?.paymentWireBik ?? ''}
            onChange={setFieldMemoized('paymentWireBik')}
            placeholder={'БИК'}
            isInvalid={invalidFields.paymentWireBik}
            validateField={validateFieldMemoized('paymentWireBik')}
          />
        </div>
        <div className="gift-certificate-step-controls">
          {step > 1 ? <button className="button-big-primary-wide muted disabled" onClick={() => step > 1 && setStep(step - 1)}><div className="util-arrow-left" /> Вернуться</button> : <div></div>}
          {step != 4 && <button
            className={`button-big-primary-wide ${(buttonDisabled || (step == 5 && isSubmitting)) ? 'disabled' : ''}`}
            onClick={(e) => {
              if (step != 5) {
                !buttonDisabled && setStep(step + 1)
              } else {
                !buttonDisabled && handleSubmit(e)
              }
            }}
          >
            {step == 3 ? 'Оплата' : step == 5 ? (isSubmitting ? 'Оформляем' : 'Оформить') : 'Следующий шаг'}
          </button>}
        </div>
      </div>
    </Modal>
  )
}

export default EducationModal