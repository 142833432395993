import { useRef, useEffect } from 'react'

const useDescription = description => {
  const documentDefined = typeof document !== 'undefined'
  const originalDescription = documentDefined ? document.querySelector('meta[name="description"]').content : null

  console.dir(originalDescription)

  useEffect(() => {
    if (!documentDefined) return

    if (document.querySelector('meta[name="description"]').content !== description) document.querySelector('meta[name="description"]').content = description

    return () => {
      document.querySelector('meta[name="description"]').content = originalDescription
    }
  }, [description])
}

export default useDescription